import React from "react";

export default function Teen({ data }) {
  const resultDTO = data?.casinoIndividualResultDTO || data;

  const casinoIndividualResultMaps =
    resultDTO?.casinoIndividualResultMaps || [];
  const casinoCardResultMaps = resultDTO?.casinoCardResultMaps || [];

  const getResultByType = (resultFor) => {
    const resultObj = casinoIndividualResultMaps.find(
      (item) => item.resultFor === resultFor
    );
    return resultObj ? resultObj.result[0] : "";
  };

  const playerACards =
    casinoCardResultMaps.find((item) => item.resultFor === "Player A")
      ?.result || [];
  const playerBCards =
    casinoCardResultMaps.find((item) => item.resultFor === "Player B")
      ?.result || [];

  const winner = getResultByType("Winner");

  const matchTime = resultDTO?.createdAt
    ? new Date(resultDTO.createdAt).toLocaleString()
    : "";

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        <div className="casino-result-round-id">
          <span>
            <b>Round Id: </b> {resultDTO?.marketId || ""}
          </span>
          <span>
            <b>Match Time: </b>
            {matchTime}
          </span>
        </div>
        <div className="row mt-2">
          <div className="col-md-6 text-center">
            <h4 className="result-title">Player A</h4>
            <div className="casino-result-cards">
              {winner === "Player A" && (
                <div className="casino-winner-icon">
                  <i className="fas fa-trophy" />
                </div>
              )}
              {playerACards.map((card, index) => (
                <img
                  key={index}
                  src={`https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/${card}.jpg`}
                  alt={`Player A Card ${index + 1}`}
                />
              ))}
            </div>
          </div>
          <div className="col-md-6 text-center">
            <h4 className="result-title">Player B</h4>
            <div className="casino-result-cards">
              {winner === "Player B" && (
                <div className="casino-winner-icon">
                  <i className="fas fa-trophy" />
                </div>
              )}
              {playerBCards.map((card, index) => (
                <img
                  key={index}
                  src={`https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/${card}.jpg`}
                  alt={`Player B Card ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              <div className="casino-result-desc-item">
                <div>Winner:</div>
                <div>{getResultByType("Winner")}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>3 Baccarat:</div>
                <div>{getResultByType("3 Baccarat")}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>&nbsp;</div>
                <div>
                  (A : {playerACards.length} | B : {playerBCards.length})
                </div>
              </div>
              <div className="casino-result-desc-item">
                <div>Total:</div>
                <div>{getResultByType("Total")}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Pair Plus:</div>
                <div>{getResultByType("Pair Plus")}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Red Black:</div>
                <div>{getResultByType("Red/Black")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
