import React from 'react'

export default function Roulet() {
  return (
    <div className="tab-content">
    <div className="tab-pane active" id="all-casino">
      <div className="casino-list">
        <div className="casino-list-item">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </div>
        <div className="casino-list-item">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </div>
        <div className="casino-list-item">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette1.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </div>
        <div className="casino-list-item">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </div>
      </div>
    </div>
  </div>
  

  )
}
