import { AXIOS_INSTANCE } from "..";
import { getToken, BASE_URL } from "../../utils/constants";

export const getAllSoccerMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/soccer/all-matches-dashboard`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllTennisMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/tennis/all-matches-dashboard`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const placeSoccerBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/soccer/bet/place`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const placeTennisBetAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/tennis/bet/place`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMyBetsSoccerAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/soccer/my-bet/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMyBetsTennisAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/tennis/my-bet/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatchDetailsSoccerAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/soccer/match-details/${eventId}`,
      {
        // params: {
        //   eventId,
        // },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatchDetailsTennisAPI = async (eventId, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/tennis/match-details/${eventId}`,
      {
        // params: {
        //   eventId,
        // },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllMatchesOddsSoccerAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/soccer/all-matches-dashboard`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllMatchesOddsTennisAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/tennis/all-matches-dashboard`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getSoccerOddsByMatchIdAPI = async (matchId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/soccer/odds/${matchId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getTennisOddsByMatchIdAPI = async (matchId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/tennis/odds/${matchId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getSoccerEventDetailsAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/soccer/event-data/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getTennisEventDetailsAPI = async (eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/tennis/event-data/${eventId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
