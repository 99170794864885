import React from "react";
import Teen20 from "./resultModals/Teen20";
import TeenpattiOpen from "./resultModals/TeenpattiOpen";
import Teen9 from "./resultModals/Teen9";
import Teenpatti1Day from "./resultModals/Teenpatti1Day";

export default function GenericIndividualResult({ data, shortName, onClose }) {
  console.log("shortName", shortName);
  const renderResult = () => {
    switch (shortName) {
      case "teenpatti20":
        return <Teen20 data={data} />;
      case "teenpattiopen":
        return <TeenpattiOpen data={data} />;
      case "teenpattitest":
        return <Teen9 data={data} />;
      case "teenpatti1day":
        return <Teenpatti1Day data={data} />;
      default:
        return renderDefaultResult();
    }
  };

  const renderDefaultResult = () => {
    return (
      <div className="modal-body">
        <h1>Hello</h1>
      </div>
    );
  };

  const getModalTitle = () => {
    switch (shortName) {
      case "teenpatti20":
        return "Teen Patti Result";
      case "teenpattiopen":
        return "Teenpatti Open Result";
      case "teenpattitest":
        return "Teenpatti Test Result";
      case "teenpatti1day":
        return "Teenpatti 1 Day Result";
      default:
        return "Game Result";
    }
  };

  return (
    <div
      role="dialog"
      aria-modal="true"
      className="fade modal show"
      tabIndex={-1}
      style={{ paddingRight: 17, display: "block" }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">{getModalTitle()}</div>
            <button
              onClick={onClose}
              type="button"
              className="btn-close"
              aria-label="Close"
            />
          </div>
          {renderResult()}
        </div>
      </div>
    </div>
  );
}
