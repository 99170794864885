import React from 'react'

export default function TeenPati() {
  return (
    <div className="tab-content">
    <div className="tab-pane active" id="all-casino">
      <div className="casino-list">
        <div className="casino-list-item">
          <a href="/casino/teen41">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen41.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen42">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen42.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen33">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen33.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen3">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen32">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen32.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen20b">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen20b.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teenmuf">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teenmuf.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/patti2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/patti2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen20">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen9">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen9.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen8">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen8.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen6">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div>
      </div>
    </div>
  </div>
  
  )
}
