import React from 'react'

export default function DragonTiger() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      <div className="casino-list-item">
        <a href="/casino/dt20">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/dt6">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/dtl20">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dtl20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/dt202">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt202.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>
  )
}
