import React from "react";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import GameSidebar from "../gameComponents/GameSidebar";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
  cricketMatchOddsPL,
} from "../profitLoss";

import {
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getMatchDetailsCricketAPI,
  getMyBetsCricketAPI,
  placeCricketBetAPI,
  placeFancyCricketBetAPI,
} from "../../../service/cricket";
import { toast } from "react-hot-toast";
import { applyFancyFor, DEFAULT_BET_AMOUNT } from "../../../utils/constants";
import { getLiveStreamingUrlAPI } from "../../../service/AuthService";
import LadderModal from "../gameComponents/LadderModal";
import { getMatchDetailsSoccerAPI, getMatchDetailsTennisAPI, getMyBetsSoccerAPI, getMyBetsTennisAPI, getSoccerEventDetailsAPI, getSoccerOddsByMatchIdAPI, getTennisEventDetailsAPI, getTennisOddsByMatchIdAPI, placeSoccerBetAPI, placeTennisBetAPI } from "../../../service/soccer&tennis";
export default function TennisGame() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [gameOdds, setGameOdds] = useState({});
  const [gameEvent, setGameEvent] = useState([]);
  const [betPayload, setBetPayload] = useState(null);
  const [eventExposureAndSettings, setEventExposureAndSettings] = useState({});
  const [userBets, setUserBets] = useState([]);
  const [selectedFancy, setSelectedFancy] = useState("Normal");
  const [showLadderForMarket, setShowLadderForMarket] = useState();
  const [isTvOpen, setIsTvOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("odds");
  const getGameEventDetails = useCallback(async () => {
    const { response, code } = await getTennisEventDetailsAPI(id);
    if (code === 200) {
      setGameEvent(response);
    }
  }, [id]);

  const getCricketOddsByMatchId = useCallback(async () => {
    const { response, code } = await getTennisOddsByMatchIdAPI(id);
    if (code === 200) {
      setGameOdds(response);
    }
  }, [id]);
  const getEventExposureAndSettings = useCallback(async () => {
    const { response, code } = await getMatchDetailsTennisAPI(id);
    if (code === 200) {
      setEventExposureAndSettings(response);
    }
  }, [id]);
  const applyBlinkEffect = (element) => {
    element.classList.add("blink");

    setTimeout(() => {
      element.classList.remove("blink");
    }, 1000);
  };

  useEffect(() => {
    const layButtons = document.querySelectorAll(".market-odd-box");
    const observers = [];
    layButtons.forEach((layButton) => {
      const observer = new MutationObserver(() => {
        applyBlinkEffect(layButton);
      });
      observer.observe(layButton, {
        childList: true,
        subtree: true,
        characterData: true,
      });
      observers.push(observer);
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, [gameOdds]);
  const handleAmountChange = (amount = DEFAULT_BET_AMOUNT) => {
    let calculation;

    if (betPayload?.isFancy) {
      let fancyPayload = {
        amount: amount,
        eventId: parseInt(betPayload.eventId),
        marketId: betPayload.marketId,
        marketName:
          betPayload.marketName != "MATCH_ODDS" ||
          betPayload.marketName != "BOOKMAKER_ODDS_1" ||
          betPayload.marketName != "BOOKMAKER_ODDS_2"
            ? betPayload.gameType
            : betPayload.marketName,
        gameType: betPayload.gameType,
      };

      if (betPayload.type === "lay") {
        fancyPayload["layRate"] = betPayload.oddSize;
        fancyPayload.oddsLay = betPayload?.odd;
        if (betPayload.gameType == "fancy1") {
          fancyPayload["layRate"] = betPayload?.odd;
          fancyPayload.oddsLay = betPayload?.odd;
        }
      } else {
        fancyPayload["backRate"] = betPayload?.oddSize;
        fancyPayload.oddsBack = betPayload?.odd;
        if (betPayload.gameType == "fancy1") {
          fancyPayload["backRate"] = betPayload?.odd;
          fancyPayload.oddsBack = betPayload?.odd;
        }
      }

      if (
        applyFancyFor.find(
          (item) => item?.toLowerCase() == betPayload?.gameType?.toLowerCase()
        )
      ) {
        if (betPayload?.type == "lay") {
          if (betPayload?.gameType == "fancy1") {
            fancyPayload.profit = cricketMatchOddsPL(
              betPayload?.odd,
              amount,
              betPayload?.type
            ).loss;
            fancyPayload.loss = cricketMatchOddsPL(
              betPayload?.odd,
              amount,
              betPayload?.type
            ).profit;
          } else {
            fancyPayload.profit = cricketFancyPL(
              betPayload?.oddSize,
              amount,
              betPayload?.type
            ).loss;
            fancyPayload.loss = cricketFancyPL(
              betPayload?.oddSize,
              amount,
              betPayload?.type
            ).profit;
          }
        } else {
          if (betPayload?.gameType == "fancy1") {
            fancyPayload.profit = cricketMatchOddsPL(
              betPayload?.odd,
              amount,
              betPayload?.type
            ).profit;
            fancyPayload.loss = cricketMatchOddsPL(
              betPayload?.odd,
              amount,
              betPayload?.type
            ).loss;
          } else {
            fancyPayload.profit = cricketFancyPL(
              betPayload?.oddSize,
              amount,
              betPayload?.type
            ).profit;
            fancyPayload.loss = cricketFancyPL(
              betPayload?.oddSize,
              amount,
              betPayload?.type
            ).loss;
          }
        }
      } else {
        fancyPayload.profit = cricketMatchOddsPL(
          betPayload?.odd,
          amount,
          betPayload?.type
        ).profit;
        fancyPayload.loss = cricketMatchOddsPL(
          betPayload?.odd,
          amount,
          betPayload?.type
        ).loss;
        fancyPayload.selectionId = betPayload.selectedId;
        fancyPayload.runnerName = betPayload.runnerName;
        if (betPayload?.type === "lay") {
          fancyPayload["layRate"] = betPayload?.odd;
        } else {
          fancyPayload["backRate"] = betPayload?.odd;
        }
      }
      setBetPayload({
        ...betPayload,
        ...fancyPayload,
      });
      return;
    } else if (betPayload?.isBookMaker) {
      calculation = cricketBookMakerPL(
        betPayload?.odd,
        amount,
        betPayload?.type
      );
    } else {
      calculation = casinoPL(betPayload?.odd, amount, betPayload?.type);
    }
    setBetPayload({
      ...betPayload,
      profit: parseFloat(calculation.profit).toFixed(2),
      loss: parseFloat(calculation.loss).toFixed(2),
      amount: amount,
    });
  };
  const handleBetPlace = async () => {
    if (betPayload?.isFancy) {
      const {  code, status } = await placeFancyCricketBetAPI(betPayload);
      if (code === 200) {
        setBetPayload(null);
        toast.success("Bet placed successfully");
      } else if (code == "ERR_BAD_RESPONSE") {
        toast.error("Something went wrong");
      } else {
        toast.error(status);
      }
    } else {
      const {  code, status } = await placeTennisBetAPI(betPayload);
      if (code === 200) {
        setBetPayload(null);
        toast.success("Bet placed successfully");
      } else if (code == "ERR_BAD_RESPONSE") {
        toast.error("Something went wrong");
      } else {
        toast.error(status);
      }
    }
  };
  const handleMatchOddClick = (odd, oddSize, item, isBack = true) => {
    let runnersAndExposure = [];

    handleAmountChange(DEFAULT_BET_AMOUNT);
    let calculation = casinoPL(
      odd,
      betPayload?.amount ? betPayload?.amount : DEFAULT_BET_AMOUNT,
      isBack ? "back" : "lay"
    );
    gameOdds?.matchOddsResponseDTO[0]?.oddDetailsDTOS.map((m) => {
      runnersAndExposure.push({
        runnerName: m.runnerName,
        exposure:
          m?.selectionId === item?.selectionId
            ? parseFloat(calculation.profit).toFixed(2)
            : "-" + parseFloat(calculation.loss).toFixed(2),
        preExposure: eventExposureAndSettings?.plExposureResponseDTOs
          ?.find(
            (i) => i.marketIdExternal === gameOdds?.matchOddsResponseDTO[0]?.marketId
          )
          ?.runnerPlExposureDTOS?.find(
            (runner) => runner.selectionId == m.selectionId
          )?.exposure,
      });
    });
    let payload = {
      profit: parseFloat(calculation.profit).toFixed(2),
      loss: parseFloat(calculation.loss).toFixed(2),
      marketId: gameOdds?.matchOddsResponseDTO[0]?.marketId,
      selectionId: item.selectionId,
      runnerName: item.runnerName,
      type: isBack ? "back" : "lay",
      eventId: id,
      marketName: gameOdds?.matchOddsResponseDTO[0]?.marketName,
      gameType: gameOdds?.matchOddsResponseDTO[0]?.marketName,
      odd: odd,
      oddSize: oddSize,
      amount: betPayload?.amount ? betPayload?.amount : DEFAULT_BET_AMOUNT,
      runnersAndExposure,
    };

    payload[isBack ? "back" : "lay"] = parseFloat(odd);
    setBetPayload(payload);
  };
  const handleBookMakerOddClick = (
    odd,
    oddSize,
    item,
    bm,
    isBack = true,
    isOtherMarket = false
  ) => {
    let runnersAndExposure = [];
    handleAmountChange(DEFAULT_BET_AMOUNT);
    let calculation = cricketBookMakerPL(
      odd,
      betPayload?.amount ? betPayload?.amount : DEFAULT_BET_AMOUNT,
      isBack ? "back" : "lay"
    );
    let market = isOtherMarket
      ? gameOdds?.otherMarketOdds[0]
      : gameOdds?.bookMakerOdds[0][bm];
    market?.oddDetailsDTOS.map((m) => {
      runnersAndExposure.push({
        runnerName: m.runnerName,
        exposure:
          m?.selectionId === item?.selectionId
            ? parseFloat(calculation.profit).toFixed(2)
            : "-" + parseFloat(calculation.loss).toFixed(2),
      });
    });
    let payload = {
      profit: parseFloat(calculation.profit).toFixed(2),
      loss: parseFloat(calculation.loss).toFixed(2),
      marketId: market?.marketId,
      selectionId: item.selectionId,
      runnerName: item.runnerName,
      type: isBack ? "back" : "lay",
      eventId: id,
      marketName: market?.marketName,
      gameType: market?.marketName,
      odd: odd,
      oddSize: oddSize,
      amount: betPayload?.amount ? betPayload?.amount : DEFAULT_BET_AMOUNT,
      isBookMaker: true,
      runnersAndExposure,
    };
    payload[isBack ? "back" : "lay"] = parseFloat(odd);
    setBetPayload(payload);
  };
  const handleFancyOddClick = (
    odd,
    oddSize,
    item,
    gameType,
    isBack = true
  ) => {
    let marketData = gameOdds?.fancyOdds?.find(
      (game) => game.gameType == gameType
    );
   

    let calculation;
    let payload = {};
    let profit = 0;
    let loss = 0;
    if (isBack) {
      payload["backRate"] = oddSize;
      payload["oddsBack"] = odd;
      calculation = cricketFancyPL(
        oddSize,
        betPayload?.amount ? betPayload?.amount : DEFAULT_BET_AMOUNT,
        "back"
      );
      profit = parseFloat(calculation.profit);
      loss = parseFloat(calculation.loss);
    } else {
      payload["layRate"] = oddSize;
      payload["oddsLay"] = odd;
      calculation = cricketFancyPL(
        oddSize,
        betPayload?.amount ? betPayload?.amount : DEFAULT_BET_AMOUNT,
        "lay"
      );
      profit = parseFloat(calculation.loss);
      loss = parseFloat(calculation.profit);
    }
    let type = isBack ? "back" : "lay";
    let amount = 0;

    if (
      applyFancyFor.find(
        (item) => item?.toLowerCase() == gameType?.toLowerCase()
      )
    ) {
      if (type == "lay") {
        if (gameType == "fancy1") {
          profit = cricketMatchOddsPL(odd, amount, type).loss;
          loss = cricketMatchOddsPL(odd, amount, type).profit;
        } else {
          profit = cricketFancyPL(oddSize, amount, type).loss;
          loss = cricketFancyPL(oddSize, amount, type).profit;
        }
      } else {
        if (gameType == "fancy1") {
          profit = cricketMatchOddsPL(odd, amount, type).profit;
          loss = cricketMatchOddsPL(odd, amount, type).loss;
        } else {
          profit = cricketFancyPL(oddSize, amount, type).profit;
          loss = cricketFancyPL(oddSize, amount, type).loss;
        }
      }
    } else {
      profit = cricketMatchOddsPL(odd, amount, type).profit;
      loss = cricketMatchOddsPL(odd, amount, type).loss;
     
      if (type === "lay") {
        payload["layRate"] = odd;
      } else {
        payload["backRate"] = odd;
      }
    }

    payload = {
      ...payload,
      marketId: item?.marketId,
      selectionId: item.selectionId,
      runnerName: item.runnerName,
      type: isBack ? "back" : "lay",
      eventId: id,
      isBack: isBack,
      marketName: marketData?.marketName,
      gameType: gameType,
      odd: odd,
      oddSize: oddSize,
      amount: 0,
      profit,
      loss,
      isFancy: true,
    };
    setBetPayload(payload);
  };
  useEffect(() => {
    getGameEventDetails();
    getCricketOddsByMatchId();
    getEventExposureAndSettings();
    let interval = setInterval(() => {
      getCricketOddsByMatchId();
      getEventExposureAndSettings();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const fetchMyBets = useCallback(async () => {
    const { response, code } = await getMyBetsTennisAPI(id);
    setUserBets(code == 200 ? response : []);
  }, [id]);
  useEffect(() => {
    fetchMyBets();
    let interval = setInterval(() => {
      fetchMyBets();
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const [channelId, setChannelId] = useState();
  const getLiveStreamingUrl = async () => {
    const { response } = await getLiveStreamingUrlAPI(gameEvent?.eventId);
    setChannelId(response);
  };

  useEffect(() => {
    getLiveStreamingUrl();
  }, [gameEvent?.eventId]);
  const amountFormatter = (amount, toL = true) => {
    if (amount > 10000000) {
      return amount / 10000000 + "Cr";
    } else if (amount > 100000) {
      return amount / 100000 + "L";
    } else if (amount > 1000) {
      return amount / 1000 + "K";
    } else {
      return amount;
    }
  };
  const getLadderForMarket = (marketId) => {
    return eventExposureAndSettings?.plExposureResponseDTOs?.find(
      (i) => i.marketIdExternal === marketId
    )?.runnerPlExposureDTOS;
  };
  const checkIfFloat = (value) => {
    return value % 1 != 0 ? parseFloat(value).toFixed(2) : parseInt(value);
  };
  return (
    <div className="center-main-container detail-page">
      <div className="center-container">
        <div className="detail-page-container">
          <div className="game-header">
            <span>{gameEvent?.eventName}</span>
            <span className="float-right">
              {moment(gameEvent?.startDate).format("DD-MM-YYYY HH:mm")}
            </span>
          </div>
          <div className="scorecard">
            <iframe
              style={{
                width: "100%",
                height: "auto",
              }}
              src={`https://animscore.proexch.in/#/score1/${gameEvent?.eventId}`}
            />
          </div>

          <ul className="nav nav-tabs d-xl-none menu-tabs">
            <li onClick={() => setActiveTab("odds")} className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab">
                Odds
              </a>
            </li>
            <li onClick={() => setActiveTab("bets")} className="nav-item">
              <a className="nav-link" data-bs-toggle="tab">
                Matched Bet ({userBets?.length})
              </a>
            </li>
            <li onClick={() => setIsTvOpen(!isTvOpen)} className="nav-item">
              <a className="nav-link">
                <i className="fas fa-tv" />
              </a>
            </li>
          </ul>
          {isTvOpen && (
            <iframe
              style={{
                width: "100%",
                height: "200px",
              }}
              src={`https://video.proexch.in/tv/static?chid=${channelId}`}
            />
          )}
          {activeTab == "bets" ? (
            <div className="table-responsive w-100">
              <table className="table">
                <thead>
                  <tr>
                    <th>Matched Bet</th>
                    <th className="text-end">Odds</th>
                    <th className="text-end">Stake</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets?.map((bet) => (
                    <tr className={bet?.back ? "back" : "lay"}>
                      <td>
                        <span className="bet-name">{bet?.runnerName}</span>
                      </td>
                      <td className="text-end">
                        <span className="bet-odd">
                          {bet?.back ? bet?.back : bet?.lay}
                        </span>
                      </td>
                      <td className="text-end">
                        <span className="bet-stake">{bet?.amount}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              {gameOdds?.matchOddsResponseDTO
 && (
                <div className="game-market market-4 ">
                  <div className="market-title">
                    <span>MATCH_ODDS</span>
                  </div>
                  <div className="market-header">
                    <div className="market-nation-detail">
                      <span className="market-nation-name">
                        Max:{" "}
                        {amountFormatter(eventExposureAndSettings?.maxStack)}
                      </span>
                    </div>
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box back">
                      <b>Back</b>
                    </div>
                    <div className="market-odd-box lay">
                      <b>Lay</b>
                    </div>
                    <div className="market-odd-box" />
                    <div className="market-odd-box no-border" />
                  </div>
                  <div
                    className="market-body "
                    data-title={gameOdds?.matchOddsResponseDTO[0]?.marketStatus}
                  >
                    {gameOdds?.matchOddsResponseDTO[0]?.oddDetailsDTOS
                      ?.sort((a, b) =>
                        a?.runnerName?.localeCompare(b.runnerName)
                      )
                      ?.map((item) => (
                        <div
                          className={`market-row ${
                            item?.status?.toUpperCase() != "ACTIVE"
                              ? "suspended-row"
                              : ""
                          }`}
                          data-title={item?.status}
                        >
                          <div className="market-nation-detail">
                            <span className="market-nation-name">
                              {item?.runnerName}
                            </span>

                            <div className="market-nation-book">
                              <div className="market-book">
                                <span
                                  style={{
                                    color:
                                      eventExposureAndSettings?.plExposureResponseDTOs
                                        ?.find(
                                          (i) =>
                                            i.marketIdExternal ===
                                            gameOdds?.matchOddsResponseDTO[0]?.marketId
                                        )
                                        ?.runnerPlExposureDTOS?.find(
                                          (runner) =>
                                            runner.selectionId ==
                                            item.selectionId
                                        )?.exposure < 0
                                        ? "red"
                                        : "green",
                                  }}
                                >
                                  {
                                    eventExposureAndSettings?.plExposureResponseDTOs
                                      ?.find(
                                        (i) =>
                                          i.marketIdExternal ===
                                          gameOdds?.matchOddsResponseDTO[0]?.marketId
                                      )
                                      ?.runnerPlExposureDTOS?.find(
                                        (runner) =>
                                          runner.selectionId ==
                                          item.selectionId
                                      )?.exposure
                                  }
                                </span>
                              </div>
                              {gameOdds?.matchOddsResponseDTO[0]?.marketId ===
                                betPayload?.marketId && (
                                <span
                                  class={`market-live-book d-none d-xl-block ${
                                    betPayload?.selectionId ===
                                    item?.selectionId
                                      ? "text-success"
                                      : "text-danger"
                                  } `}
                                >
                                  {betPayload?.selectionId ===
                                  item?.selectionId
                                    ? betPayload?.profit
                                    : "-" + betPayload?.loss}
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              handleMatchOddClick(
                                item?.back3,
                                item?.backSize3,
                                item
                              )
                            }
                            className="market-odd-box   back2 "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back3)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize3}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleMatchOddClick(
                                item?.back2,
                                item?.backSize2,
                                item
                              )
                            }
                            className="market-odd-box  back1  "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back2)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize2}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleMatchOddClick(
                                item?.back1,
                                item?.backSize1,
                                item
                              )
                            }
                            className="market-odd-box back   "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back1)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize1}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleMatchOddClick(
                                item?.lay1,
                                item?.laySize1,
                                item,
                                false
                              )
                            }
                            className="market-odd-box lay   "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay1)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize1}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleMatchOddClick(
                                item?.lay2,
                                item?.laySize2,
                                item,
                                false
                              )
                            }
                            className="market-odd-box  lay1  "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay2)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize2}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleMatchOddClick(
                                item?.lay3,
                                item?.laySize3,
                                item,
                                false
                              )
                            }
                            className="market-odd-box   lay2 "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay3)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize3}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {gameOdds?.bookMakerOdds &&
                gameOdds?.bookMakerOdds?.length > 0 && (
                  <div className="game-market market-4 ">
                    <div className="market-title">
                      <span>Bookmaker</span>
                    </div>
                    <div className="market-header">
                      <div className="market-nation-detail">
                        <span className="market-nation-name">
                          Min:{" "}
                          {amountFormatter(
                            eventExposureAndSettings?.minStack
                          )}
                          &nbsp; Max:{" "}
                          {amountFormatter(
                            eventExposureAndSettings?.bookmakerMaxStack
                          )}
                        </span>
                      </div>
                      <div className="market-odd-box no-border d-none d-md-block" />
                      <div className="market-odd-box no-border d-none d-md-block" />
                      <div className="market-odd-box back">
                        <b>Back</b>
                      </div>
                      <div className="market-odd-box lay">
                        <b>Lay</b>
                      </div>
                      <div className="market-odd-box" />
                      <div className="market-odd-box no-border" />
                    </div>
                    <div
                      className="market-body "
                      data-title={
                        gameOdds?.bookMakerOdds[0]?.bm1?.marketStatus
                      }
                    >
                      {gameOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS
                        ?.sort((a, b) =>
                          a.runnerName.localeCompare(b.runnerName)
                        )
                        ?.map((item) => (
                          <>
                            {" "}
                            <div
                              className={`market-row ${
                                item?.status?.toUpperCase() != "ACTIVE"
                                  ? "suspended-row"
                                  : ""
                              }`}
                              data-title={item?.status}
                            >
                              <div className="market-nation-detail">
                                <span className="market-nation-name">
                                  {item?.runnerName}
                                </span>
                                <div className="market-nation-book">
                                  <div className="market-book">
                                    <span
                                      style={{
                                        color:
                                          eventExposureAndSettings?.plExposureResponseDTOs
                                            ?.find(
                                              (i) =>
                                                i.marketIdExternal ===
                                                gameOdds?.bookMakerOdds[0]
                                                  ?.bm1?.marketId
                                            )
                                            ?.runnerPlExposureDTOS?.find(
                                              (runner) =>
                                                runner.selectionId ==
                                                item.selectionId
                                            )?.exposure < 0
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {
                                        eventExposureAndSettings?.plExposureResponseDTOs
                                          ?.find(
                                            (i) =>
                                              i.marketIdExternal ===
                                              gameOdds?.bookMakerOdds[0]?.bm1
                                                ?.marketId
                                          )
                                          ?.runnerPlExposureDTOS?.find(
                                            (runner) =>
                                              runner.selectionId ==
                                              item.selectionId
                                          )?.exposure
                                      }
                                    </span>
                                  </div>
                                  {gameOdds?.bookMakerOdds[0]?.bm1
                                    ?.marketId === betPayload?.marketId && (
                                    <span
                                      class={`market-live-book d-none d-xl-block ${
                                        betPayload?.selectionId ===
                                        item?.selectionId
                                          ? "text-success"
                                          : "text-danger"
                                      } `}
                                    >
                                      {betPayload?.selectionId ===
                                      item?.selectionId
                                        ? betPayload?.profit
                                        : "-" + betPayload?.loss}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  handleBookMakerOddClick(
                                    item?.back3,
                                    item?.backSize3,
                                    item,
                                    "bm1"
                                  )
                                }
                                className="market-odd-box   back2 "
                              >
                                <span className="market-odd">
                                  {item?.back3 ? item?.back3 : 0}
                                </span>
                              </div>
                              <div
                                onClick={() =>
                                  handleBookMakerOddClick(
                                    item?.back2,
                                    item?.backSize2,
                                    item,
                                    "bm1"
                                  )
                                }
                                className="market-odd-box  back1  "
                              >
                                <span className="market-odd">
                                  {item?.back2 ? item?.back2 : 0}
                                </span>
                              </div>
                              <div
                                onClick={() =>
                                  handleBookMakerOddClick(
                                    item?.back1,
                                    item?.backSize1,
                                    item,
                                    "bm1"
                                  )
                                }
                                className="market-odd-box back   "
                              >
                                <span className="market-odd">
                                  {checkIfFloat(item?.back1)}
                                </span>
                                <span className="market-volume">
                                  {amountFormatter(item?.backSize1)}
                                </span>
                              </div>
                              <div
                                onClick={() =>
                                  handleBookMakerOddClick(
                                    item?.lay1,
                                    item?.laySize1,
                                    item,
                                    "bm1",
                                    false
                                  )
                                }
                                className="market-odd-box lay   "
                              >
                                <span className="market-odd">
                                  {checkIfFloat(item?.lay1)}
                                </span>
                                <span className="market-volume">
                                  {amountFormatter(item?.laySize1)}
                                </span>
                              </div>
                              <div
                                onClick={() =>
                                  handleBookMakerOddClick(
                                    item?.lay2,
                                    item?.laySize2,
                                    item,
                                    "bm1",
                                    false
                                  )
                                }
                                className="market-odd-box  lay1  "
                              >
                                <span className="market-odd">
                                  {item?.lay2 ? item?.lay2 : 0}
                                </span>
                              </div>
                              <div
                                onClick={() =>
                                  handleBookMakerOddClick(
                                    item?.lay3,
                                    item?.laySize3,
                                    item,
                                    "bm1",
                                    false
                                  )
                                }
                                className="market-odd-box   lay2 "
                              >
                                <span className="market-odd">
                                  {item?.lay3 ? item?.lay3 : 0}
                                </span>
                              </div>
                            </div>
                            <div className="market-row">
                              <marquee className="market-remark">
                                {item?.remark}
                              </marquee>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                )}
              {gameOdds?.otherMarketOdds && (
                <div className="game-market market-4 ">
                  <div className="market-title">
                    <span>TIED MATCH</span>
                  </div>
                  <div className="market-header">
                    <div className="market-nation-detail">
                      <span className="market-nation-name">
                        Max:{" "}
                        {amountFormatter(eventExposureAndSettings?.maxStack)}
                      </span>
                    </div>
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box back">
                      <b>Back</b>
                    </div>
                    <div className="market-odd-box lay">
                      <b>Lay</b>
                    </div>
                    <div className="market-odd-box" />
                    <div className="market-odd-box no-border" />
                  </div>
                  <div
                    className="market-body "
                    data-title={gameOdds?.otherMarketOdds[0]?.marketStatus}
                  >
                    {gameOdds?.otherMarketOdds[0]?.oddDetailsDTOS
                      ?.sort((a, b) =>
                        a?.runnerName?.localeCompare(b.runnerName)
                      )
                      ?.map((item) => (
                        <div
                          className={`market-row ${
                            item?.status?.toUpperCase() != "ACTIVE"
                              ? "suspended-row"
                              : ""
                          }`}
                          data-title={item?.status}
                        >
                          <div className="market-nation-detail">
                            <span className="market-nation-name">
                              {item?.runnerName}
                            </span>

                            <div className="market-nation-book">
                              <div className="market-book">
                                <span
                                  style={{
                                    color:
                                      eventExposureAndSettings?.plExposureResponseDTOs
                                        ?.find(
                                          (i) =>
                                            i.marketIdExternal ===
                                            gameOdds?.otherMarketOdds[0]
                                              ?.marketId
                                        )
                                        ?.runnerPlExposureDTOS?.find(
                                          (runner) =>
                                            runner.selectionId ==
                                            item.selectionId
                                        )?.exposure < 0
                                        ? "red"
                                        : "green",
                                  }}
                                >
                                  {
                                    eventExposureAndSettings?.plExposureResponseDTOs
                                      ?.find(
                                        (i) =>
                                          i.marketIdExternal ===
                                          gameOdds?.otherMarketOdds[0]
                                            ?.marketId
                                      )
                                      ?.runnerPlExposureDTOS?.find(
                                        (runner) =>
                                          runner.selectionId ==
                                          item.selectionId
                                      )?.exposure
                                  }
                                </span>
                              </div>
                              {gameOdds?.otherMarketOdds[0]?.marketId ===
                                betPayload?.marketId && (
                                <span
                                  class={`market-live-book d-none d-xl-block ${
                                    betPayload?.selectionId ===
                                    item?.selectionId
                                      ? "text-success"
                                      : "text-danger"
                                  } `}
                                >
                                  {betPayload?.selectionId ===
                                  item?.selectionId
                                    ? betPayload?.profit
                                    : "-" + betPayload?.loss}
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            onClick={() =>
                             handleBookMakerOddClick(
                                item?.back3,
                                item?.backSize3,
                               item,
                                "bm1",
                                true,
                                true
                              )
                            }
                            className="market-odd-box   back2 "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back3)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize3}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleBookMakerOddClick(
                                item?.back2,
                                item?.backSize2,
                                item,
                                true,
                                true
                              )
                            }
                            className="market-odd-box  back1  "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back2)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize2}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                             handleBookMakerOddClick(
                                item?.back1,
                                item?.backSize1,
                               item,
                                true,
                               true,
                                true
                              )
                            }
                            className="market-odd-box back   "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.back1)}
                            </span>
                            <span className="market-volume">
                              {item?.backSize1}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleBookMakerOddClick(
                                item?.lay1,
                                item?.laySize1,
                                item,
                                false,
                                false,
                                true

                              )
                            }
                            className="market-odd-box lay   "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay1)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize1}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleBookMakerOddClick(
                                item?.lay2,
                                item?.laySize2,
                                item,
                                false,
                                false,
                                true

                              )
                            }
                            className="market-odd-box  lay1  "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay2)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize2}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              handleBookMakerOddClick(
                                item?.lay3,
                                item?.laySize3,
                                item,
                                false,
                                false,
                                true

                              )
                            }
                            className="market-odd-box   lay2 "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.lay3)}
                            </span>
                            <span className="market-volume">
                              {item?.laySize3}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {gameOdds?.fancyOdds &&
                gameOdds?.fancyOdds.length > 0 &&
                gameOdds?.fancyOdds
                  ?.sort((a, b) => {
                    if (a.gameType.toLowerCase() == "normal") {
                      return -1;
                    }
                    return 1;
                  })
                  ?.map((fancy) => (
                    <div className="game-market market-6">
                      <div className="market-title">
                        <span>{fancy?.gameType}</span>
                      </div>
                      <div className="row row10">
                        <div className="col-md-6">
                          <div className="market-header">
                            <div className="market-nation-detail" />
                            <div className="market-odd-box lay">
                              <b>No</b>
                            </div>
                            <div className="market-odd-box back">
                              <b>Yes</b>
                            </div>
                            <div className="fancy-min-max-box" />
                          </div>
                        </div>
                        <div className="col-md-6 d-none d-xl-block">
                          <div className="market-header">
                            <div className="market-nation-detail" />
                            <div className="market-odd-box lay">
                              <b>No</b>
                            </div>
                            <div className="market-odd-box back">
                              <b>Yes</b>
                            </div>
                            <div className="fancy-min-max-box" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="market-body "
                        data-title={fancy?.status}
                      >
                        <div className="row row10">
                          {fancy?.oddDetailsDTOS
                            ?.sort((a, b) => {
                              const bandA = a.runnerName;
                              const bandB = b.runnerName;

                              let comparison = 0;
                              if (bandA > bandB) {
                                comparison = 1;
                              } else if (bandA < bandB) {
                                comparison = -1;
                              }
                              return comparison;
                            })
                            ?.map((item, index) => (
                              <div className="col-md-6">
                                <div
                                  className={`fancy-market ${
                                    item?.status?.toLowerCase() ==
                                      "suspended" ||
                                    item?.status?.toLowerCase() ==
                                      "ball running" ||
                                    item?.status?.toLowerCase() == "inactive"
                                      ? "suspended-row"
                                      : item?.status?.toLowerCase()
                                  }`}
                                  data-title={item?.status?.toLowerCase()}
                                >
                                  <div className="market-row">
                                    <div className="market-nation-detail">
                                      <span
                                        onClick={() => {
                                          setShowLadderForMarket(
                                            item.marketId
                                          );
                                        }}
                                        className="market-nation-name"
                                      >
                                        {item?.runnerName}
                                      </span>
                                      <span
                                        class={`market-book float-end  ${
                                          eventExposureAndSettings?.plExposureResponseDTOs?.find(
                                            (i) =>
                                              i.marketIdExternal ===
                                              item.marketId
                                          )?.exposure < 0
                                            ? "text-danger"
                                            : "text-success"
                                        }`}
                                      >
                                        {
                                          eventExposureAndSettings?.plExposureResponseDTOs?.find(
                                            (i) =>
                                              i.marketIdExternal ===
                                              item.marketId
                                          )?.exposure
                                        }
                                      </span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        handleFancyOddClick(
                                          item?.lay1,
                                          item?.laySize1,
                                          item,
                                          fancy?.gameType,
                                          false
                                        )
                                      }
                                      className="market-odd-box lay "
                                    >
                                      <span className="market-odd">
                                        {checkIfFloat(item?.lay1)}
                                      </span>
                                      <span className="market-volume">
                                        {amountFormatter(item?.laySize1)}
                                      </span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        handleFancyOddClick(
                                          item?.back1,
                                          item?.backSize1,
                                          item,
                                          fancy?.gameType,
                                          true
                                        )
                                      }
                                      className="market-odd-box back "
                                    >
                                      <span className="market-odd">
                                        {checkIfFloat(item?.back1)}
                                      </span>
                                      <span className="market-volume">
                                        {amountFormatter(item?.backSize1)}
                                      </span>
                                    </div>
                                    <div className="fancy-min-max-box">
                                      <div className="fancy-min-max">
                                        <span className="w-100 d-block">
                                          Min:{" "}
                                          {amountFormatter(
                                            eventExposureAndSettings?.sessionMinStack
                                          )}
                                        </span>
                                        <span className="w-100 d-block">
                                          Max:{" "}
                                          {amountFormatter(
                                            eventExposureAndSettings?.sessionMaxStack
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
            </>
          )}
        </div>
      </div>
      <GameSidebar
        betPayload={betPayload}
        id={id}
        handleAmountChange={handleAmountChange}
        handleBetPlace={handleBetPlace}
        userBets={userBets}
        betHandler={handleBetPlace}
        setBetPayload={setBetPayload}
        game="tennis"
        gameEvent={gameEvent}
      />
      <LadderModal
        showLadderForMarket={showLadderForMarket}
        data={getLadderForMarket(showLadderForMarket)}
        onClose={() => setShowLadderForMarket(null)}
        setShowModal={() => setShowLadderForMarket(null)}
      />
    </div>
  );
}
