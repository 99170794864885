import React from 'react'

export default function AllCasino() {
  return (
    <div className="tab-content">
    <div className="tab-pane active" id="all-casino">
      <div className="casino-list">
        <div className="casino-list-item">
          <a href="/casino/superover3">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/goal">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/goal.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/ab4">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab4.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/lucky15">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky15.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/superover2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen41">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen41.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen42">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen42.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
            }}
          />
        </div>
        <div className="casino-list-item">
          <a href="/casino/sicbo2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/sicbo2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
            }}
          />
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen33">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen33.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/sicbo">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/sicbo.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/ballbyball">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ballbyball.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen32">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen32.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette1.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
            }}
          />
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen20">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen9">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen9.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen8">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen8.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/poker">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/poker20">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/poker6">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/baccarat">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/baccarat.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/baccarat2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/baccarat2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/dt20">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/dt6">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/dtl20">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dtl20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/dt202">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt202.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/card32">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/card32.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/card32eu">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/card32eu.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/ab20">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/abj">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/abj.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/lucky7">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/lucky7eu">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7eu.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/3cardj">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/3cardj.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/war">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/war.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/worli">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/worli.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/worli2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/worli2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/aaa">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/aaa.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/btable">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/btable.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/lottcard">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lottcard.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/cricketv3">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cricketv3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/cmatch20">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmatch20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/cmeter">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmeter.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen6">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/queen">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/queen.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/race20">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/lucky7eu2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7eu2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/superover">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/trap">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/trap.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/patti2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/patti2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teensin">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teensin.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teenmuf">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teenmuf.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/race17">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race17.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen20b">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen20b.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/trio">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/trio.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/notenum">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/notenum.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/kbc">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/kbc.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen120">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen120.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen1">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen1.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
            }}
          />
        </div>
        <div className="casino-list-item">
          <a href="/casino/ab3">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/aaa2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/aaa2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/race2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/teen3">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/dum10">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dum10.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/cmeter1">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmeter1.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
              }}
            />
          </a>
        </div>
      </div>
    </div>
  </div>
  )
}
