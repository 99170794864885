import React, { useEffect, useState } from "react";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import "./index.scoped.css";
import CasinoPage from "./components/CasinoPage";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
export default function Poker1Day() {
  const GAME_ID = "POKER_1_DAY";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);

  return (
    <>
      <CasinoPage
        casino={casinoData?.data}
        VIDEO_ID={CASINO_VIDEO.poker1Day}
        GAME_ID={GAME_ID}
        shortName={"cards32a poker-20 Poker_oneday"}
        gameName={"1 Day Poker"}
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table ng-tns-c1798067373-1 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box ng-tns-c1798067373-1"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-left-box ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-header ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-1"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-0 ng-tns-c1798067373-1"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        Min:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        100 {/**/}
                        {/**/}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-1 ng-tns-c1798067373-1"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        Max:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        300000 {/**/}
                        {/**/}
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-1"
                >
                  BACK
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box lay ng-tns-c1798067373-1"
                >
                  Lay
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-body ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-detail ng-tns-c1798067373-1"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-name ng-tns-c1798067373-1"
                    >
                      {" "}
                      Player A{" "}
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {getCasinoPlExposureByRunner(casinoPlData, "Player A")}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-1 casino_odds_row ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box back ng-tns-c1798067373-1"
                      onClick={() => {
                        setBet("Player A", casinoData, dispatch, "back");
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        {getOddForEntity("Player A", casinoData, true)}
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        {getOddForEntity("Player A", casinoData, true, true)}
                      </p>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box lay ng-tns-c1798067373-1"
                      onClick={() => {
                        setBet("Player A", casinoData, dispatch, "lay");
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        {getOddForEntity("Player A", casinoData, false)}
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        {getOddForEntity("Player A", casinoData, false, true)}
                      </p>
                    </div>
                  </div>
                </div>
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-detail ng-tns-c1798067373-1"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-name ng-tns-c1798067373-1"
                    >
                      {" "}
                      Player B{" "}
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {getCasinoPlExposureByRunner(casinoPlData, "Player B")}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-1 casino_odds_row ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box back ng-tns-c1798067373-1"
                      onClick={() => {
                        setBet("Player B", casinoData, dispatch, "back");
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        {getOddForEntity("Player B", casinoData, true)}
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        {getOddForEntity("Player B", casinoData, true, true)}
                      </p>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box lay ng-tns-c1798067373-1"
                      onClick={() => {
                        setBet("Player B", casinoData, dispatch, "lay");
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        {getOddForEntity("Player B", casinoData, false)}
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        {getOddForEntity("Player B", casinoData, false, true)}
                      </p>
                    </div>
                  </div>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-left-box ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="market-title d-lg-none d-block ng-tns-c1798067373-1"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1"
                >
                  Bonus Bet
                </span>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-header ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-1"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-0 ng-tns-c1798067373-1"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        Min:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        100 {/**/}
                        {/**/}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-1 ng-tns-c1798067373-1"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        Max:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        300000 {/**/}
                        {/**/}
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-1"
                >
                  BACK
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-1"
                >
                  BACK
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-body ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-row ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-detail ng-tns-c1798067373-1"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-name ng-tns-c1798067373-1"
                    >
                      Player A
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-1 casino_odds_row ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box back ng-tns-c1798067373-1"
                      onClick={() => {
                        setBet(
                          "Player A 2 card Bonus",
                          casinoData,
                          dispatch,
                          "back"
                        );
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        Player A 2 card Bonus
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        {getCasinoPlExposureByRunner(
                          casinoPlData,
                          "Player A 2 card Bonus"
                        )}
                      </p>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box back ng-tns-c1798067373-1"
                      onClick={() => {
                        setBet(
                          "Player A 7 card bonus",
                          casinoData,
                          dispatch,
                          "back"
                        );
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        Player A 7 card bonus
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        {getCasinoPlExposureByRunner(
                          casinoPlData,
                          "Player A 7 card bonus"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-row ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-detail ng-tns-c1798067373-1"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-name ng-tns-c1798067373-1"
                    >
                      Player B
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-1 casino_odds_row ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box back ng-tns-c1798067373-1"
                      onClick={() => {
                        setBet(
                          "Player B 2 card Bonus",
                          casinoData,
                          dispatch,
                          "back"
                        );
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        Player B 2 card Bonus
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        {getCasinoPlExposureByRunner(
                          casinoPlData,
                          "Player B 2 card Bonus"
                        )}
                      </p>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box back ng-tns-c1798067373-1"
                      onClick={() => {
                        setBet(
                          "Player B 7 card bonus",
                          casinoData,
                          dispatch,
                          "back"
                        );
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        Player B 7 card bonus
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        {getCasinoPlExposureByRunner(
                          casinoPlData,
                          "Player B 7 card bonus"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="remark w-100 text-right pr-2 ng-tns-c1798067373-1"
          />
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-1"
        >
          <app-casino-game-rules
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-1"
            _nghost-ng-c1761710207=""
          >
            {/**/}
            <div
              _ngcontent-ng-c1761710207=""
              className="sidebar-title ng-star-inserted"
            >
              <h4 _ngcontent-ng-c1761710207="">Rules</h4>
            </div>
            <div
              _ngcontent-ng-c1761710207=""
              className="my-bets poker1rules ng-star-inserted"
            >
              <div _ngcontent-ng-c1761710207="" className="table-responsive">
                <table
                  _ngcontent-ng-c1761710207=""
                  className="table table-bordered"
                >
                  <thead _ngcontent-ng-c1761710207="">
                    <tr _ngcontent-ng-c1761710207="">
                      <th
                        _ngcontent-ng-c1761710207=""
                        colSpan={2}
                        className="text-center"
                      >
                        {" "}
                        Bonus 1 (2 Cards Bonus){" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c1761710207="">
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Pair (2-10)</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 3</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">
                        A/Q or A/J Off Suited
                      </td>
                      <td _ngcontent-ng-c1761710207="">1 TO 5</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Pair (JQK)</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 10</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">A/K Off Suited</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 15</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">A/Q or A/J Suited</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 20</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">A/K Suited</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 25</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">A/A</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 30</td>
                    </tr>
                  </tbody>
                </table>
                <table
                  _ngcontent-ng-c1761710207=""
                  className="table table-bordered"
                >
                  <thead _ngcontent-ng-c1761710207="">
                    <tr _ngcontent-ng-c1761710207="">
                      <th
                        _ngcontent-ng-c1761710207=""
                        colSpan={2}
                        className="text-center"
                      >
                        {" "}
                        Bonus 2 (7 Cards Bonus){" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c1761710207="">
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Three of a Kind</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 3</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Straight</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 4</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Flush</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 6</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Full House</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 8</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Four of a Kind</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 30</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Straight Flush</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 50</td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">Royal Flush</td>
                      <td _ngcontent-ng-c1761710207="">1 TO 100</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/**/}
            {/**/}
            {/**/}
            {/**/}
          </app-casino-game-rules>
        </div>
      </CasinoPage>
    </>
  );
}
